<template>
  <div>
    <modal-vue :modal="modal" @onChange="((value) => modal = value)">
      <div class="prod-dist-modal">
        <div class="modal-header">Product Distribution</div>
        <div class="modal-data" v-html="modalContent"></div>
        <div class="modal-footer">
          <div class="actions">
            <b-button @click="toggleProductDistributionModal">Close</b-button>
          </div>
        </div>
      </div>
    </modal-vue>
    <div class="csat-options">
      <div v-if="comprehensiveCsatDriverSummary.companies" class="toggle-button">
        <div class="option-cover">
          <label for="showTotal" class="custom-checkbox">
            <input type="checkbox" name="showTotal" id="showTotal" @click="toggleVendorCsatShowTotal" :checked="showVendorCsatTotal">
            <div class="checkmark"></div>
            <p>Show Company Total</p>
          </label>
        </div>
      </div>
      <div v-if="comprehensiveCsatDriverSummary.companies" class="toggle-button">
        <dropdown-wrap @onClickOutside="onClickOutside">
          <div class="option-cover">
              <b-button @click="openDriverViewDropdown" class="driver-display-button">
                  <div class="inside-sort-button inside">
                  <p>{{getName}}</p>
                  <svg xmlns="http://www.w3.org/2000/svg" width="7.072" height="7.072" viewBox="0 0 7.072 7.072" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill="#a8aab7" d="M3.889 6.718a.5.5 0 01-.707 0L.353 3.889a.5.5 0 11.708-.707l2.474 2.475L6.01 3.182a.5.5 0 11.707.707z"/></svg>
                  </div>
              </b-button>
              <div v-if="dropdownOpen" class="view-dropdown">
                  <p class="option-header">Core Capabilities:</p>
                  <div class="view-options">
                    <div class="default-sort"  @click.prevent="onChangeOption(0)">
                      <label for="rating" class="custom-radio">
                        <input type="radio" name="displayView" id="rating" :checked="localSelected == 0">
                        <div class="radio-checkmark"></div>
                        <p>By Ratings</p>
                      </label>
                    </div>
                    <div class="default-sort"  @click.prevent="onChangeOption(1)">
                      <label for="reaction" class="custom-radio">
                        <input type="radio" name="displayView" id="reaction" :checked="localSelected == 1">
                        <div class="radio-checkmark"></div>
                        <p>By Reactions</p>
                      </label>
                    </div>
                  </div>
              </div>
          </div>
        </dropdown-wrap>
      </div>
      <div v-if="comprehensiveCsatDriverSummary.companies" class="csat-sort">
        <div class="csat-sort-inside">
          <global-sort-vue
            :selected="sort"
            :showSortBy="false"
            :options="sortOptions"
            :csatPage="true"
            @onChange="onChangeSelection" />
        </div>
      </div>
    </div>
    <div class="main-card" v-if="comprehensiveCsatDriverSummary.companies">
      <template v-if="!noData">
        <div class="local-loader-container" v-if="vendorCsatLoader">
          <div class="inava-loader"></div>
        </div>
        <div class="line-separator-copy"></div>
        <div class="custom-table-container-block-2">
          <table class="csat-table">
            <thead ref="csatTableHeader">
              <tr>
                <th class="filter">
                  {{ currentModule === 'voc' ? 'CORE CAPABILITIES' : 'KEY ATTRIBUTES' }}
                  <img src="../../assets/info-new.svg" alt="" class="info-icon" @mouseenter="tooltip($event, tooltipData, 'in')" @mouseleave="tooltip($event, tooltipData, 'out')"
                  @mousemove="tooltip($event, tooltipData, 'in')" />
                </th>
                <th class="filter" v-if="showVendorCsatTotal">ALL COMPANY TOTAL</th>
                <th
                  v-for="company, index of sortedCsatVendors"
                  :key="company.id"
                  :class="[driverViewClass, company.type]">
                  <div class="th-container">
                    <img
                      v-if="
                        (user_preference_mode == 'dark' &&
                          company.properties &&
                          company.properties.logo_dark) ||
                        (user_preference_mode == 'light' &&
                          company.properties &&
                          company.properties.logo_light)
                      "
                      :src="
                        user_preference_mode == 'dark'
                          ? company.properties.logo_dark
                          : company.properties.logo_light
                      "
                      :alt="company.type === 'company' ? company.name : company.productName"
                      :title="company.type === 'company' ? company.name : company.productName"
                      class="company-logo"
                      @click="company.type === 'company' && toggleProductDistributionModal($event, company.name)" />
                    <span v-else class="company-name" @click="toggleProductDistributionModal($event, company.name)">{{ company.type === 'company' ? company.name : company.productName }}</span>
                    <button
                      v-if="company.type === 'company'"
                      class="prod-col-toggle-button"
                      @click="toggleProductData($event, company.name, index + 1)">
                      <img v-if="!expandedVendors[company.name]" src="../../assets/plus_icon.svg" alt="expand-column-icon">
                      <img v-else src="../../assets/minus_icon.svg" alt="hide-column-icon">
                    </button>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(topicName, index) of standardTopics">
                <tr :key="'A' + index">
                  <td class="row-header tooltip-wrapper"
                    :helpText="topicName.helpText"
                    @mouseenter="showTooltip"
                    @mousemove="showTooltip"
                    @mouseleave="hideTooltip">{{topicName}}</td>
                  <template v-if="showVendorCsatTotal">
                    <td>
                        <DataCell
                          v-if="comprehensiveCsatDriverSummary.totalSummary[topicName]"
                          :topicName="topicName"
                          :ownerName="'Total'"
                          :data="comprehensiveCsatDriverSummary.totalSummary[topicName]"
                          :type="'company'"
                          :total="true" />
                        <DataCell
                          v-else
                          :topicName="topicName"
                          :ownerName="'Total'"
                          :data="emptyDriverDataObject"
                          :dataForTotal="comprehensiveCsatDriverSummary.totalSummary[topicName]"
                          :type="'company'"
                          :total="true" />
                    </td>
                    <td
                      v-for="vendor of sortedCsatVendors"
                      :key="vendor.id"
                      :class="[vendor.type]">
                      <template v-if="vendor.type === 'product'">
                        <DataCell
                        v-if="comprehensiveCsatDriverSummary.companies[vendor.owner] && comprehensiveCsatDriverSummary.companies[vendor.owner].products[vendor.productName] && comprehensiveCsatDriverSummary.companies[vendor.owner].products[vendor.productName].driverSummary[topicName]"
                        :topicName="topicName"
                        :ownerName="vendor.owner"
                        :data="comprehensiveCsatDriverSummary.companies[vendor.owner].products[vendor.productName].driverSummary[topicName]"
                        :dataForTotal="comprehensiveCsatDriverSummary.companies[vendor.owner].products[vendor.productName].driverSummary[topicName]"
                        :type="'product'"
                        :total="false" />
                        <DataCell
                          v-else
                          :topicName="topicName"
                          :ownerName="vendor.owner"
                          :data="emptyDriverDataObject"
                          :dataForTotal="comprehensiveCsatDriverSummary.totalSummary[topicName]"
                          :type="'product'"
                          :total="false" />
                      </template>
                      <template v-if="vendor.type === 'company'">
                        <DataCell
                          v-if="comprehensiveCsatDriverSummary.companies[vendor.name] && comprehensiveCsatDriverSummary.companies[vendor.name].driverSummary[topicName]"
                          :topicName="topicName"
                          :ownerName="vendor.name"
                          :data="comprehensiveCsatDriverSummary.companies[vendor.name].driverSummary[topicName]"
                          :dataForTotal="comprehensiveCsatDriverSummary.totalSummary[topicName]"
                          :type="'company'"
                          :total="false" />
                        <DataCell
                          v-else
                          :topicName="topicName"
                          :ownerName="vendor.name"
                          :data="emptyDriverDataObject"
                          :dataForTotal="comprehensiveCsatDriverSummary.totalSummary[topicName]"
                          :type="'company'"
                          :total="false" />
                      </template>
                    </td>
                  </template>
                  <template v-else>
                    <td
                      v-for="vendor of sortedCsatVendors"
                      :key="vendor.id"
                      :class="[vendor.type]">
                      <template v-if="vendor.type === 'product'">
                        <DataCell
                        v-if="comprehensiveCsatDriverSummary.companies[vendor.owner] && comprehensiveCsatDriverSummary.companies[vendor.owner].products[vendor.productName] && comprehensiveCsatDriverSummary.companies[vendor.owner].products[vendor.productName].driverSummary[topicName]"
                        :topicName="topicName"
                        :ownerName="vendor.owner"
                        :data="comprehensiveCsatDriverSummary.companies[vendor.owner].products[vendor.productName].driverSummary[topicName]"
                        :dataForTotal="comprehensiveCsatDriverSummary.companies[vendor.owner].products[vendor.productName].driverSummary[topicName]"
                        :type="'product'"
                        :total="false" />
                        <DataCell
                          v-else
                          :topicName="topicName"
                          :ownerName="vendor.owner"
                          :data="emptyDriverDataObject"
                          :dataForTotal="comprehensiveCsatDriverSummary.totalSummary[topicName]"
                          :type="'product'"
                          :total="false" />
                      </template>
                      <template v-if="vendor.type === 'company'">
                        <DataCell
                          v-if="comprehensiveCsatDriverSummary.companies[vendor.name] && comprehensiveCsatDriverSummary.companies[vendor.name].driverSummary[topicName]"
                          :topicName="topicName"
                          :ownerName="vendor.name"
                          :data="comprehensiveCsatDriverSummary.companies[vendor.name].driverSummary[topicName]"
                          :dataForTotal="comprehensiveCsatDriverSummary.totalSummary[topicName]"
                          :type="'company'"
                          :total="false" />
                        <DataCell
                          v-else
                          :topicName="topicName"
                          :ownerName="vendor.name"
                          :data="emptyDriverDataObject"
                          :dataForTotal="comprehensiveCsatDriverSummary.totalSummary[topicName]"
                          :type="'company'"
                          :total="false" />
                      </template>
                    </td>
                  </template>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </template>
      <template v-if="noData">
        <NoDataContainerVue type="cSatDriver" />
      </template>
    </div>
    <div v-if="!comprehensiveCsatDriverSummary.companies">
      <NoDataContainerVue type="cSatDriver" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import DropdownWrap from '@/components/Common/DropdownWrap.vue'
import DataCell from '@/components/CsatDrivers/DataCell.vue'
import NoDataContainerVue from '@/components/Common/NoDataContainer/NoDataContainer.vue'
import { metaTitle, sapCompetitors } from '@/constant/data'
import { isEmpty } from 'lodash'
import GlobalSortVue from '@/components/GlobalSort/GlobalSort.vue'
import tooltip from '@/util/tooltip'
import ModalVue from '@/views/Admin/components/Modal.vue'

/** This is the parent component/view for the **CSAT by Vendor Drivers** page. */
export default {
  name: 'CsatDriversByVendor',
  metaInfo () {
    return {
      title: metaTitle.vendorCsat
    }
  },
  components: {
    NoDataContainerVue,
    GlobalSortVue,
    DataCell,
    DropdownWrap,
    ModalVue
  },
  data () {
    return {
      noData: false,
      dropdownOpen: false,
      driverViewList: [
        { name: 'By Ratings', value: 0 },
        { name: 'By Reaction', value: 1 }
      ],
      localSelected: 0,
      tooltipData: 'CSAT by Vendor measures include Standard attributes that are generic across companies.',
      modal: false,
      modalContent: null,
      standardTopics: [
        'App Extensibility',
        'Customer Support',
        'Customization',
        'Ease of Implementation',
        'Upgrades',
        'User Interface',
        'Value for Money'
      ],
      emptyDriverDataObject: {
        csatAvg: null,
        dislike: null,
        dislikeCount: null,
        like: null,
        likeCount: null,
        helpText: null,
        isStandard: true,
        parentId: null,
        reviewCount: null
      }
    }
  },
  computed: {
    ...mapState('filters', [
      'selectedSourceList',
      'selectedCsatVendors',
      'sortedCsatVendors',
      'selectedIndustries',
      'selectedCompanies',
      'ActiveVendorCsatTimePeriod',
      'vendorCsatReferenceArray'
    ]),
    ...mapState('csatdriver', [
      'comprehensiveCsatDriverSummary',
      'sortOptions',
      'sort',
      'csatVendorDriverView',
      'showVendorCsatTotal',
      'expandedVendors',
      'vendorCsatLoader'
    ]),
    ...mapState('common', ['user_preference_mode', 'currentModule', 'active_page']),
    ...mapState('user', ['userDetails']),
    payloadData () {
      return {
        module: this.currentModule,
        source: this.selectedSourceList.map((item) => item.id),
        company: this.selectedCsatVendors.map(d => d.id),
        startTime: this.ActiveVendorCsatTimePeriod.range[0],
        endTime: this.ActiveVendorCsatTimePeriod.range[1],
        industries: this.selectedIndustries.map((item) => item.id),
        companySize: this.selectedCompanies.map((item) => item.id),
        requestingModuleId: this.userDetails.modules.find(m => m.module_name === 'voc').module_id
      }
    },
    getName () {
      return this.driverViewList.find((option) => option.value === this.csatVendorDriverView).name
    },
    driverViewClass () {
      switch (this.csatVendorDriverView) {
        case 1:
          return 'likeView'
        default:
          return 'percentView'
      }
    },
    watchable () {
      return JSON.stringify(this.selectedSourceList) + JSON.stringify(this.selectedIndustries) + JSON.stringify(this.selectedCompanies) + JSON.stringify(this.selectedCsatVendors) + JSON.stringify(this.ActiveVendorCsatTimePeriod)
    }
  },
  watch: {
    watchable () {
      if (this.watchable.length) {
        this.getStatistics()
      }
    },
    sort () {
      this.sortData()
    },
    comprehensiveCsatDriverSummary () {
      if (this.isEmpty(this.comprehensiveCsatDriverSummary)) {
        this.noData = true
      } else {
        this.sortData()
        // The logic below is for all cases where a data refresh occurs and product columns are expanded

        // Create a list of currently displayed columns
        let currentList = [...this.sortedCsatVendors]
        // Iterate through each company whose columns are expanded
        for (const companyName in this.expandedVendors) {
          // If the api response does not contain the company, filter out all the objects from the currentList that are products of the current company.
          if (!this.comprehensiveCsatDriverSummary.companies[companyName]) {
            currentList = currentList.filter(obj => {
              return !(obj.name === companyName && obj.type === 'product')
            })
            // Remove this company from the expandedVendors map
            this.updateExpandedVendors({ name: companyName, type: 'hide' })
          } else {
            /** If the api response contains the company, 3 things are possible:
             * 1. There was no change in remaining columns
             * 2. The product columns are expanded, and a product column needs to be removed
             * 3. The product columns are expanded, and a new product column needs to be added
            */

            // Get list of products for the current company/vendor, from the currentList and also from the api response
            const productList = currentList.filter(obj => obj.name === companyName && obj.type === 'product')
            const productsInResponse = Object.keys(this.comprehensiveCsatDriverSummary.companies[companyName].products)

            // Helper variables used to calculate how many product columns for a company are expanded
            let numOfIncreasedColumns = 0
            let numOfReducedColumns = 0

            // Check if a product column should be added or removed. Compare productList length, and num of products in response. If the response has less products, some expanded columns need to be hidden
            if (productsInResponse.length < productList.length) {
              for (const product of productList) {
                // If the api response has less products, remove it from the currentList variable and update the numOfReducedColumns variable
                if (!this.comprehensiveCsatDriverSummary.companies[companyName].products[product.productName]) {
                  numOfReducedColumns += 1
                  currentList = currentList.filter(obj => {
                    return !(obj.productName === product.productName && obj.type === 'product')
                  })
                }
              }
              // Calculate the new number of expanded columns that this company has and update the map
              const updatedCount = this.expandedVendors[companyName] - numOfReducedColumns
              this.updateExpandedVendors({ name: companyName, count: updatedCount, type: 'expand' })
            } else if (productsInResponse.length > productList.length) {
              // If the api response has more products for this company than are listed, then that product needs to be added to the currentList and the numberOfIncreasedColumns variable needs to be updated for each column that will be added
              for (const productName of productsInResponse) {
                const prodObj = productList.find(obj => obj.productName === productName)
                if (!productList.includes(prodObj)) {
                  numOfIncreasedColumns += 1
                  const obj = {
                    id: this.comprehensiveCsatDriverSummary.companies[companyName].products[productName].productId,
                    name: companyName,
                    type: 'product',
                    owner: companyName,
                    productName: productName,
                    properties: this.comprehensiveCsatDriverSummary.companies[companyName].products[productName].properties
                  }
                  currentList.push(obj)
                }
              }
              // Calculate the new number of expanded columns that this company has and update the map
              const updatedCount = this.expandedVendors[companyName] + numOfIncreasedColumns
              this.updateExpandedVendors({ name: companyName, count: updatedCount, type: 'expand' })
            }
          }
        }
        // Update the list of displayed vendors and products
        this.updateSortedCsatVendors(currentList)
        this.updateVendorCsatLoader(false)
        this.noData = false
      }
    }
  },
  mounted () {
    this.getCsatVendors({
      clientId: this.userDetails.clientId
    })
    this.getCumulativeSourceByClientId({
      clientId: this.userDetails.clientId
    })
    this.getCumulativeTimeSubscriptionVoc()
    if (sessionStorage.getItem('csatVendorDriverView')) {
      this.localSelected = JSON.parse(sessionStorage.getItem('csatVendorDriverView'))
    }
    this.updateDriverDisplayMode({ page: this.active_page, value: this.localSelected })
  },
  updated () {
    if (sessionStorage.getItem('csatVendorDriverView')) {
      this.localSelected = JSON.parse(sessionStorage.getItem('csatVendorDriverView'))
    }
    if (this.$refs.csatTableHeader) {
      const tableHeader = this.$refs.csatTableHeader
      document.querySelector('.line-separator-copy').style.setProperty('--header-height', tableHeader.clientHeight + 'px')
    }
  },
  methods: {
    isEmpty,
    ...mapActions('csatdriver', [
      'getCsatDriverSummaryByVendor',
      'updateCsatDriverData',
      'updateSort',
      'updatePercentageMode',
      'updateDriverDisplayMode',
      'toggleVendorCsatShowTotal',
      'updateExpandedVendors',
      'updateVendorCsatLoader'
    ]),
    ...mapActions('filters', ['updateSortedCsatVendors', 'getCsatVendors', 'getCumulativeTimeSubscriptionVoc', 'getCumulativeSourceByClientId', 'updateVendorCsatReferenceArray']),
    ...mapActions('analytics', ['updateEvent']),
    tooltip,
    /**
     * This method updates the CSAT Driver display. It can show one of the following views:
     * - CSAT by Vendor Drivers Table (Ratings View)
     * - CSAT by Vendor Driver Table (Reactions View)
     * @param value {Number} 0, 1
     * @public
     */
    onChangeOption (value) {
      this.updateDriverDisplayMode({ page: this.active_page, value: value })
    },
    onClickOutside (event) {
      this.dropdownOpen = false
    },
    /**
     * This methods toggles the dropdown used to select the CSAT Drivers view
     */
    openDriverViewDropdown () {
      this.dropdownOpen = !this.dropdownOpen
      if (this.dropdownOpen) {
        this.updateEvent({
          userId: this.userDetails.userId,
          event: 'click',
          label: 'Driver View Dropdown',
          pageUrl: window.location.href
        })
      }
    },
    /**
     * This method is used to update the sorting for the drivers. It is only used in the table views
     * @public
     */
    onChangeSelection (data) {
      this.updateSort(data)
    },
    /**
     * This method gets all the data required for filling the CSAT Drivers Table
     */
    getStatistics () {
      if (
        this.currentModule === 'voc' &&
        this.selectedSourceList.length &&
        this.selectedCsatVendors.length &&
        this.selectedIndustries.length &&
        this.selectedCompanies.length &&
        this.ActiveVendorCsatTimePeriod &&
        this.ActiveVendorCsatTimePeriod.group_by
      ) {
        this.updateVendorCsatLoader(true)
        this.getCsatDriverSummaryByVendor(this.payloadData)
      } else {
        this.noData = true
      }
      this.checkDataSelection()
    },
    sortData () {
      if (this.comprehensiveCsatDriverSummary.companies) {
        if (this.sort.type !== 'topic_name') {
          let sortingColumn = Object.entries(this.comprehensiveCsatDriverSummary.companies[Object.keys(this.comprehensiveCsatDriverSummary.companies)[0]].driverSummary)
          let sortingKey = null
          switch (this.sort.type) {
            case 'total_count':
              sortingKey = 'reviewCount'
              break
            case 'c_sat_avg':
              sortingKey = 'csatAvg'
              break
            case 'like':
              sortingKey = 'like'
              break
            default:
              sortingKey = 'reviewCount'
              break
          }
          sortingColumn = sortingColumn.sort((a, b) => {
            return b[1][sortingKey] > a[1][sortingKey]
              ? this.sort.orderBy === 'asc'
                ? -1
                : 1
              : b[1][sortingKey] < a[1][sortingKey]
                ? this.sort.orderBy === 'desc'
                  ? -1
                  : 1
                : 0
          })
          sortingColumn = sortingColumn.map(obj => obj[0])
          let datalessDrivers = [...this.standardTopics].filter(obj => !sortingColumn.includes(obj))
          datalessDrivers = datalessDrivers.sort()
          this.standardTopics = [...sortingColumn, ...datalessDrivers]
        } else {
          this.standardTopics = this.standardTopics.sort((a, b) => {
            return b[0] > a[0]
              ? this.sort.orderBy === 'asc'
                ? -1
                : 1
              : b[0] < a[0]
                ? this.sort.orderBy === 'desc'
                  ? -1
                  : 1
                : 0
          })
        }
      }
    },
    /**
     * This method makes sure that the minimum data needed to create the table/chart is present. If it's not, then the *No Data* container is shown instead.
     * @public
     */
    checkDataSelection () {
      if (
        !(
          this.selectedCsatVendors.length &&
          this.selectedSourceList.length &&
          this.selectedIndustries.length &&
          this.selectedCompanies.length
        )
      ) {
        this.updateCsatDriverData([])
        this.noData = true
      }
    },
    /**
     * This method shows the tooltip. Intended for the CSAT Drivers table. Currently not in use
     */
    showTooltip (event, d) {
      this.tooltipFun(event, d, 'in')
    },
    /**
     * This method hides the tooltip. Intended for the CSAT Drivers table. Currently not in use
     */
    hideTooltip (event, d) {
      this.tooltipFun(event, d, 'out')
    },
    /**
     * This method controls the content shown in the tooltip
     */
    tooltipFun (event, d, type) {
      let data = ''
      switch (type) {
        case 'in':
          data = event.target.getAttribute('helptext')
          break
      }
      if (data || type === 'out') {
        tooltip(event, data, type, true)
      }
    },
    toggleProductData (event, companyName, index) {
      if (this.expandedVendors[companyName]) {
        this.sortedCsatVendors.splice(index, this.expandedVendors[companyName])
        // Get the number of product columns which are expanded for this company
        const totalNumOfCompetitorProds = this.expandedVendors[companyName]
        // Subtract them from current reference array length
        const sizeOfNewReference = this.vendorCsatReferenceArray.length - totalNumOfCompetitorProds
        // Get new array using this length
        this.updateVendorCsatReferenceArray([...this.sortedCsatVendors].splice(0, sizeOfNewReference))
        this.updateExpandedVendors({ name: companyName, type: 'hide' })
      } else {
        let count = 0
        for (const product in this.comprehensiveCsatDriverSummary.companies[companyName].products) {
          const obj = {
            id: this.comprehensiveCsatDriverSummary.companies[companyName].products[product].productId,
            name: companyName,
            type: 'product',
            owner: companyName,
            productName: product,
            properties: this.comprehensiveCsatDriverSummary.companies[companyName].products[product].properties
          }
          this.sortedCsatVendors.splice(index, 0, obj)
          index += 1
          count += 1
        }
        this.updateExpandedVendors({ name: companyName, count: count, type: 'expand' })
        // Use the mapper to get an array of only those values which belong to competitors present in expandedVendors. Use the reduce function to add up all the values in the array and get the totalNumOfCompetitorProds
        const totalNumOfCompetitorProds = Object.keys(this.expandedVendors).map(item => {
          return this.expandedVendors[sapCompetitors.find(obj => {
            return obj.name === item
          }).name]
        }).reduce((accumulator, currentValue) => accumulator + currentValue)
        const sizeOfNewReference = sapCompetitors.length + totalNumOfCompetitorProds
        this.updateVendorCsatReferenceArray([...this.sortedCsatVendors].splice(0, sizeOfNewReference))
      }
    },
    /**
     * This function toggles the modal which contains product distribution for a company
     */
    toggleProductDistributionModal (event, companyName, type) {
      this.modal = !this.modal
      if (this.modal) {
        let html = ''
        if (this.comprehensiveCsatDriverSummary) {
          if (this.comprehensiveCsatDriverSummary.companies[companyName]) {
            const products = this.comprehensiveCsatDriverSummary.companies[companyName].products
            let prodDistArray = []
            for (const prodName in products) {
              for (const { alias } of products[prodName].category) {
                prodDistArray.push({ product: prodName, category: alias, reviewCount: products[prodName].category.find(item => item.alias === alias).reviewCount })
              }
            }
            // // Filter the products array to have unique productCategory combinations. The API returns a list of all products and categories. Although the prod name and category alias might be the same for the elements in the array, the other values are different. This additional processing step is required.
            prodDistArray = prodDistArray.filter((value, index, self) => {
              return index === self.findIndex((element) => {
                return (element.product === value.product && element.category === value.category && element.reviewCount === value.reviewCount)
              })
            })
            let tableData = ''
            let count = 0
            for (const prod of prodDistArray) {
              count++
              tableData += `<tr>
              <td>${count}.</td>
              <td>${prod.product}</td>
              <td>${prod.category}</td>
              <td>${prod.reviewCount}</td>
              </tr>`
            }
            html = `
            <table class="vendor-csat-prod-distribution">
              <thead>
                <tr>
                  <th></th>
                  <th>Product</th>
                  <th>Category</th>
                  <th># Reviews</th>
                </tr>
              </thead>
              <tbody>
                ${tableData}
              </tbody>
            </table>
            `
          } else {
            html = '<p>No data available for this vendor</p>'
          }
        }
        this.modalContent = html
      }
    }
  }
}
</script>
<style lang="scss" src="./CSatDrivers.scss" scoped />
<style lang="scss" scoped>

::v-deep table.csat-table {
  th.product, td.product {
    background: #c4c8f829;
  }
  th.company {
    button {
      cursor: pointer;
      background: transparent;
      border: none;
    }
    span.company-name:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .th-container {
    display: flex;
    align-items: center;
    justify-content: center;
    img, span {
      width: 75%;
    }
    .prod-col-toggle-button {
      margin-left: 5px;
      img {
        min-width: 12px;
        width: 12px;
      }
    }
  }
}

::v-deep .modal {
  z-index: 200;
  .modal-content {
    width: fit-content;
  }
}

::v-deep .prod-dist-modal {
  display: flex;
  margin: auto;
  background: var(--tooltip-background-color);
  width: fit-content;
  color: white;
  flex-direction: column;
  border-radius: 4px;
  padding: 0px 25px 0px;
  .modal-data {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    position: relative;
    .vendor-csat-prod-distribution {
      thead {
        position: sticky;
        top: 54px;
        th {
          color: white;
          background-color: #2d3747;
          padding: 5px;
          font-weight: normal;
        }
      }
      tbody {
        td {
          padding: 5px;
          &:nth-child(1) {
            text-align: center;
          }
        }
      }
    }
  }
  .modal-header {
    font-size: 16px;
    font-weight: 500;
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: left;
    position: sticky;
    top: 0px;
    background: var(--tooltip-background-color);
    z-index: 1;
    display: flex;
    align-content: center;
  }
  .modal-footer {
    position: sticky;
    bottom: 0;
    background: var(--tooltip-background-color);
    padding: 15px;
  }
  .actions {
    margin-left: auto;
    text-align: end;
    .button {
      font-size: 14px;
      border: none;
      margin-left: 5px;
      background-color: var(--inava-pink);
      color: white;
      &:hover {
        background-color: var(--inava-primary-light);
        color: var(--inava-black);
        transition: 0.3s ease-out;
      }
      &:active {
        background-color: var(--inava-pink);
        color: white;
        transition: background-color 0.3s ease-out;
      }
    }
  }
}
</style>
