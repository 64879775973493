<template>
  <div ref="pro" class="progres-container" @click="onClick">
    <b-progress
      class="progress"
      :class="[progressColor, total && 'total', !pointer && 'no-pointer']"
      :value="Number(value)"
      :max="max"
      ref="pros"></b-progress>
    <div v-if="!noReview" class="show-num" :style="[{ left, color }]">
      <p ref="findWidth">{{ value }}</p>
    </div>
    <div v-if="noReview" class="show-num-copy">
      {{ 'No reviews' }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProgressBar',
  // props: ['value', 'max', 'noReview', 'onChange'],
  props: {
    /** The value of the progress bar (not the number displayed in the progress bar)  */
    value: {
      type: [String, Number]
    },
    /** The max value of the progress bar (at which it will full be covered) */
    max: {
      type: Number
    },
    /** This prop indicates whether or not there is data available for the table to render */
    noReview: {
      type: Boolean
    },
    /** This prop is a function passed from the parent component. */
    onChange: {
      type: Function
    },
    /** This prop contains a string which is passed as a classname to the progress bar. It controls the color of the bar. */
    progressColor: {
      type: String
    },
    total: {
      type: Boolean
    },
    pointer: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      left: '',
      color: ''
    }
  },
  watch: {
    value () {
      if (!this.noReview) {
        this.setValues()
      }
    },
    max () {
      if (!this.noReview) {
        this.setValues()
      }
    }
  },
  mounted () {
    if (!this.noReview) {
      this.setValues()
    }
  },
  methods: {
    /** This method handles the behaviour when a progress bar is clicked. It redirects the use to the **Comments** page, showing only the reviews that were alluded to by the progress bar.
     * @public
     */
    onClick () {
      this.$emit('onChange')
    },
    /**
     * This method calculates the values to be displayed in the progress bar, along with their styling
     * @author Ashish Mangukiya <ashish.mangukiya@sganalytics.com>
     * @public
     */
    setValues () {
      const progressBarWidth = document.querySelector('progress').clientWidth
      const temp = (this.value / this.max) * 100
      const calc = (temp / 100) * progressBarWidth
      const width = this.getWidth()
      if (calc <= width + 10) {
        this.left = calc + 'px'
        this.color = '#707070'
      } else {
        this.left = calc - width - 10 + 'px'
        this.color = '#FFFFFF'
      }
    },

    /**
     * The width of a single character is calculated by dividing the width of the string by it's length. This single-character width is multiplied by the length of the new value (typecasted as a String). This is important because the new value contains an updated length. Simply returning *findWidth.clientWidth* will return old values, leading to irregular spacing when a double-digit value is changed to single-digit and vice-versa.
     *
     * @summary Returns the width of the string used to display the progress bar value.
     * @author Mohammed Omar Siddiqui <mohammedomar.siddiqui@sganalytics.com>
     * @return {Number} - The width of the String containing the new value
     * @public
     */
    getWidth () {
      // New better method
      const findWidth = this.$refs.findWidth
      // console.log(findWidth)
      // console.log('InnerText length of findWidth', findWidth.innerText.length)
      const width = findWidth.clientWidth / findWidth.innerText.length
      // console.log('Width of a single character', width)
      // console.log('Length of new value', String(this.value).length)
      return String(this.value).length * width
    }
  }
}
</script>

<style lang="scss" scoped>
.progres-container {
  width: 100px;
  position: relative;
  cursor: pointer;
  .show-num {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 14px;
    text-align: center;
    p {
      margin: 0px;
      font-family: 'Quicksand';
      color: var(--c-sat-bars-text-color);
      font-size: 0.75rem;
      margin-left: 5px;
    }
  }
  .show-num-copy {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: var(--csat-no-reviews-text-color);
    font-size: 14px;
    left: 5px;
    width: 100px;
  }
}
.no-pointer {
  cursor: default;
}

::v-deep .progress{
    border-radius: 0px !important;
}
</style>
